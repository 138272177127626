export enum Icons {
  IconAboutMe = "icon-about-me",
  IconArrowDown = "icon-arrow-down",
  IconArrowUp_2 = "icon-arrow-up-2",
  IconArrowUp = "icon-arrow-up",
  IconAt = "icon-at",
  IconCloseCircle = "icon-close-circle",
  IconContact = "icon-contact",
  IconDiscordLogo = "icon-discord-logo",
  IconFacebookLogo = "icon-facebook-logo",
  IconGithubLogo = "icon-github-logo",
  IconGitlabLogo = "icon-gitlab-logo",
  IconGlobe = "icon-globe",
  IconGraduationCap = "icon-graduation-cap",
  IconHand = "icon-hand",
  IconHeart = "icon-heart",
  IconHelpOutline = "icon-help-outline",
  IconHelp = "icon-help",
  IconInstagramLogo = "icon-instagram-logo",
  IconLight = "icon-light",
  IconLinkedinLogo = "icon-linkedin-logo",
  IconLoading = "icon-loading",
  IconMail = "icon-mail",
  IconMoonOutline = "icon-moon-outline",
  IconMoonSharp = "icon-moon-sharp",
  IconMusicNoteOff = "icon-music-note-off",
  IconMusicNote = "icon-music-note",
  IconSearch = "icon-search",
  IconSendRegular = "icon-send-regular",
  IconSettings = "icon-settings",
  IconSkills = "icon-skills",
  IconSmile = "icon-smile",
  IconSnapchatLogo = "icon-snapchat-logo",
  IconTelephoneFill = "icon-telephone-fill",
  IconUserGraduate = "icon-user-graduate",
  IconUser = "icon-user",
  IconVrHeadsetSolid = "icon-vr-headset-solid",
  IconVrHeadset = "icon-vr-headset",
  IconWaveLarge = "icon-wave-large",
  IconWaveSmall = "icon-wave-small",
  IconWorkExperience = "icon-work-experience",
  IconX = "icon-x",
}
